import './App.css';

function App() {
  return (
    <div className="App">
      <section className="NiceSection">
        <h1>Welcome to EZ Assets Calculators</h1>
        <p>Discover the power of financial freedom. A way to calculate your assets' numbers is coming soon...</p>
      </section>
    </div>
  );
}

export default App;
